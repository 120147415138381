
import { Component, Vue, Prop } from 'vue-property-decorator';
import BuySellOfferForm from '@/components/trading/buy-selloffer/BuySellOfferForm.vue';
import { Getter, Action } from 'vuex-class';
import { Asset, SellOffer } from '@/store/trading/types';


@Component({
  name: 'buy-sell-offer-view',
  components: {
    BuySellOfferForm,
  },
})
export default class BuySellOfferView extends Vue {
  @Getter('appState/isLoading') isLoading?: boolean;
  @Getter('trading/projects') projects?: Asset[];
  @Getter('trading/getSellOfferById') getSellOfferById: Function
  @Getter('trading/getProjectByAssetId') getProjectByAssetId: Function

  @Action('trading/fetchSellOffers') fetchSellOffers: any;

  private sellOffer: SellOffer = null;
  private project: Asset = null;

  async mounted() {
    const sellOfferId = parseInt(this.$route.params.sellOfferId);

    try {
      this.sellOffer = this.getSellOfferById(sellOfferId);
      this.project = this.getProjectByAssetId(this.sellOffer.asset_id);
    } catch( error ) {
      console.error(error);
    }
  }
}
