
import _ from 'lodash';
import { Component, Vue, Prop } from 'vue-property-decorator';
import MessageModal from '@/components/app/MessageModal.vue';
import { SellOffer } from '@/store/trading/types';
import { Getter, Action } from 'vuex-class';
import { Asset } from '@/store/trading/types';
import { centsToEuro, currency } from '@/filters/currency';
import SellOfferDetails from './SellOfferDetails.vue';
import Fieldset from '@/components/_common/Fieldset.vue';
import Stepper from '@/components/trading/Stepper.vue';
import { Steps } from '@/store/stepper/types';

@Component({
  name: 'buy-sell-offer-form',
  components: {
    SellOfferDetails,
    Fieldset,
    MessageModal,
    Stepper,
  },
  filters: {
    centsToEuro,
    currency,
  },
})
export default class BuySellOfferForm extends Vue {
  [ x: string ]: any;

  @Getter('appState/isLoading') isLoading?: boolean;
  @Getter('stepper/steps') steps?: string[];
  @Getter('stepper/activeStep') activeStep?: number;

  @Action('appState/startLoader') startLoader;
  @Action('appState/stopLoader') stopLoader;
  @Action('trading/buySellOffer') buySellOffer;
  @Action('stepper/setSteps') setSteps;
  @Action('stepper/nextStep') nextStep;
  @Action('stepper/backStep') backStep;
  @Action('stepper/setValidCurrentStep') setValidCurrentStep;

  @Prop({ default: null })
  project?: Asset;

  @Prop({ required: true })
  sellOffer: SellOffer;

  active: number = 0;
  createdSuccess: boolean = false;
  checkedOptions: Array<string> = [];
  message: any = null;

  buySellOfferForm = {
    pieces: null,
  }
  rules = {
    pieces: [
      {
        required: true,
        message: this.$t('buySellOffer.validationMessages.requiredPieces'),
        trigger: 'blur',
      },
      {
        type: 'number',
        min: 1,
        max: this.sellOffer.available,
        message: `${this.$t('buySellOffer.validationMessages.typeNumberPieces')} ${this.sellOffer.available}`,
        trigger: 'blur',
      },
    ],
  }

  mounted() {
    const steps: Steps = [ {
      title: this.$t('buySellOffer.stepper', { context: 'step1' }),
      valid: null,
    },
    {
      title: this.$t('buySellOffer.stepper', { context: 'step2' }),
      valid: null,
    } ];
    this.setSteps(steps);
  }

  async next() {
    if (this.firstStep && !(await this.validateForm())) {
      this.setValidCurrentStep(false);

      return;
    }

    this.active++;
    this.setValidCurrentStep(true);
    this.scrollToTop();
  }

  cancel() {
    if(this.redirectUrl){
      return window.location.href = this.redirectUrl;
    }

    return this.$router.push('/depot/trading');
  }

  public async validateForm(): Promise<boolean> {
    try {
      await this.$refs[ 'buySellOfferForm' ][ 'validate' ]();

      return true;
    } catch (error) {
      return false;
    }
  }

  back() {
    this.active--;
    this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  get totalAmount() {
    return (this.buySellOfferForm.pieces * this.sellOffer.unit_price) || 0;
  }

  get firstStep() {
    return this.active === 0;
  }

  get secondStep() {
    return this.active === 1;
  }

  async onCreateSubmit() {
    this.startLoader();
    this.message = null;

    try {
      const buySellOffer = {
        pieces: this.buySellOfferForm.pieces,
      };
      await this.buySellOffer({ id: this.sellOffer.id, buySellOffer });

      this.onSubmitSuccess();
    } catch (error) {
      this.onSubmitError(_.get(error, 'response.status') || null);
    }
  }

  get successMessage() {
    return this.$t('buySellOffer.messages.info', { context: 'buySellOfferCreated' });
  }

  onSubmitSuccess() {
    this.createdSuccess = true;
    this.message = this.successMessage;
    this.next();
    this.stopLoader();
  }

  onSubmitError(status: number) {
    this.stopLoader();
    // show a generic error message
    this.message = this.$t('buySellOffer.messages.error.generic.title') + '.<br /><br />'+this.$t('buySellOffer.messages.error.generic.text', {
        link: `<a target='_blank' href='${this.$t('buySellOffer.messages.error.generic.linkPath')}'>
              ${this.$t('buySellOffer.messages.error.generic.linkTitle')}
              </a>` });

    this.createdSuccess = false;
    const customStatus = [ 404, 410, 417 ];
   
    if (customStatus.includes(status)) {
      this.message = this.$t('buySellOffer.messages.info', {
        context: `${status}`,
        link: `<a target='_blank' href='${this.$t('buySellOffer.messages.linkPath')}'>
              ${this.$t('buySellOffer.messages.linkTitle')}
              </a>` });
    }
  }

  get hasAgreed() {
    return this.checkedOptions.length > 1;
  }
}
